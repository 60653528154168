import "./Nav.css";

const Nav = () => (
  <div className="Nav">
    <div className="Wrapper">
      ~/
      <input type="text" />
    </div>
    <div className="Buttons">
      <div>ALL ABOUT</div>
      <div className="code">1</div>
      <div className="fish">2</div>
      <div className="tone">3</div>
    </div>
  </div>
);

export default Nav;
