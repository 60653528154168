import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLinkedin,
  faGithub,
  faTwitter,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faTimes,
  faEnvelope,
  faMapMarkerAlt,
  faCopyright,
  faLaptopCode,
  faAddressCard,
  faHome,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faLinkedin,
  faGithub,
  faTwitter,
  faTwitch,
  faBars,
  faTimes,
  faEnvelope,
  faMapMarkerAlt,
  faCopyright,
  faLaptopCode,
  faAddressCard,
  faHome,
  faProjectDiagram
);
